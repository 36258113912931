.parcel-lookup {
  .parcel-label {
    .field {
      display: inline-block;
      margin-right: 2em;
      width: 45%;
      vertical-align: top;

      &:last-child {
        margin-right: inherit;
      }
    }
  }
}
