.tab-container {
  background: $white;
  border: 1px $gray-light solid;
  border-radius: 10px;

  .tab-row {
    border-bottom: 1px $gray-light solid;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .tab {
      align-items: center;
      border-bottom: 3px transparent solid;
      box-sizing: border-box;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 15px 0;
      text-align: center;
      width: 25%;

      &:first-child {
        border-top-left-radius: 9px;
      }

      &:last-child {
        border-top-right-radius: 9px;
      }

      &.active {
        background: $blue-light-background;
        border-bottom-color: $action;
        color: $action;

        &:hover {
          background: darken($blue-light-background, 1%);
          border-bottom-color: $action;
        }
      }

      &:hover {
        background: $gray-lighter;
        border-bottom-color: $gray-light;
        cursor: pointer;
      }
    }
  }

  .tab-content {
    padding: 2em;
    text-align: initial;

    @include mobile {
      margin: 1.5em 1em;
      padding: 0;
    }
  }

  @include desktop {
    min-width: 700px;
  }
}

.tab-title {
  font-size: 1.7em;
  margin-bottom: 0.4em;
  margin-top: 0;
  text-align: initial;
}
