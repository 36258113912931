.search-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;

  th,
  td {
    padding: 10px 10px;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  thead {
    tr {
      border-bottom: 1px solid $gray-light;

      th {
        background: $gray-light;
        border-top: 3px solid $action;
        border-bottom: 1px solid $gray;
        color: $gray-dark;
        text-align: left;
        font-weight: normal;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $gray-light;

      td {
        padding-bottom: 10px;
        padding-top: 10px;
        color: $gray-dark;
        position: relative;

        .title-mobile {
          display: none;
        }

        &:first-child {
          color: $black;
          font-weight: bold;

          &::before {
            content: "";
            position: absolute;
            bottom: -3px;
            width: 10px;
            height: 6px;
            background: $white;
            left: 0;
          }
        }

        &:last-child {
          width: 90px;
          padding-right: 5px;

          &::after {
            content: "";
            position: absolute;
            bottom: -3px;
            width: 10px;
            height: 6px;
            background: $white;
            right: 0;
          }
        }
      }
    }
  }

  .loader-bar {
    text-align: center;

    img {
      width: 200px;

      @include mobile {
        width: 100%;
      }
    }
  }

  @include mobile {
    border: 1px $gray-light solid;
    border-top: 3px $action solid;

    thead {
      display: none;
    }

    tbody {
      tr {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: none;

        ~ tr {
          border-top: 2px $gray solid;
        }

        td {
          width: auto !important;
          display: block;
          padding: 15px 5px !important;
          color: $black;

          ~ td {
            border-top: 1px $gray-light solid;
          }

          .title-mobile {
            display: block;
            margin-bottom: 0.3em;
            color: $gray;
            font-weight: normal;
          }

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.selectable-table {
  tr {
    cursor: pointer;

    &:hover {
      background-color: $blue-light-background;
    }
  }
}

.multiple-select-restricted-val,
.select-restricted-val {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin: 3px 3px 3px 4px;
  }
}

.listview-alert-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    min-height: auto;
    margin-top: 1.5rem;
  }
}
