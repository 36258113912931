.accordion {
  .content {
    display: none;
  }
  &.open {
    .content {
      display: block;
    }
  }
  &-top-level {
    & > .title {
      color: $gray-dark;
      cursor: pointer;
      display: none;
      font-size: 1.1em;
      font-weight: 300;
      text-transform: uppercase;
    }

    @include mobile {
      background-color: $white;
      border-top: 3px solid $action;
      box-shadow: $tile-shadow;
      box-sizing: border-box;
      display: block;
      min-height: 3em;
      padding: 0.8em 1em;
      width: 100%;

      & > .title {
        display: block;

        &:after {
          color: $black;
          content: "\E313";
          float: right;
          font-family: "Material Icons";
          font-weight: 400;
          font-size: 1.5em;
          vertical-align: bottom;
        }
      }
      &.open > .title {
        margin-bottom: 1.2em;
        &:after {
          content: "\E316";
        }
      }
    }
  }
}
