.help {
  &-tile {
    font-size: 1em;
    margin: 0.6em;
    width: 75%;
    padding-left: 1.5em;
    position: relative;
    right: 0px;
    top: 15px;
    vertical-align: top;

    @media (max-width: 991px) {
      position: static;
      height: auto;
      display: block;
      float: none;
      margin: 0;
      max-width: none;
      padding: 0.8em 1em;
      width: auto;
    }

    .divider {
      position: relative;
      right: -1.5em;
    }

    .question {
      font-size: 1.1em;
      text-transform: uppercase;

      @include mobile {
        display: none;
      }
    }

    .contact {
      padding: 0.25em;
      color: $gray;
      .info {
        position: absolute;
        right: 2em;
      }
    }

    .agency-title {
      color: $action;
      font-size: 1.5em;
      font-weight: 700;
      margin-top: 0.5em;
      margin-bottom: 0.3em;
    }

    .contacts-wrapper {
      .contact {
        position: relative;
        padding: 30px 0 30px 45px;

        & ~ .contact {
          border-top: 1px $gray-light solid;
        }

        .material-icons {
          font-size: 33px;
          color: $black;
          margin-left: -46px;
          margin-right: 13px;
          vertical-align: middle;
          width: 33px;
        }

        .contact-item {
          font-size: 1em;
          font-weight: 300;

          @include large-screen {
            font-size: 1.125em;
          }

          & ~ .contact-item {
            margin-top: 10px;
          }

          &.contact-item-phone {
            a {
              color: $action;

              &:hover {
                color: $blue-light;
              }
            }
            > span {
              display: inline-block;

              &.phone-type {
                width: 70px;
                margin-right: 7px;
                text-align: right;
              }

              &.phone-number {
                color: $gray-dark;
              }
            }
          }
          &.contact-item-email {
            word-wrap: break-word;
            overflow-wrap: break-word;

            a {
              color: $link-color;
            }
          }
        }
      }
    }
  }
}
