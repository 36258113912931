.star {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons";
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.star:before {
  content: "\E83A";
}
