.alert {
  box-sizing: border-box;
  border: 1px solid;
  position: relative;
  padding: 1em 1.5em 1em 0.5em;
  text-align: left;

  &.hidden {
    display: none;
  }

  &.fixed {
    position: fixed;

    &.bottom-right {
      bottom: 25px;
      right: 25px;
    }

    &.bottom-left {
      bottom: 25px;
      left: 25px;
    }

    &.top-right {
      top: 25px;
      right: 25px;
    }

    &.top-left {
      top: 25px;
      left: 25px;
    }

    &.center {
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      transform: translateY(-50%);

      &-top {
        transform: translateX(-50%);
        top: 100px;
        left: 50%;
      }

      &-bottom {
        transform: translateX(-50%);
        left: 50%;
        bottom: 100px;
      }
    }

    @include mobile {
      width: 90%;
      left: 50% !important;
      position: fixed;
      transform: translateX(-50%);
    }
  }

  &-icon {
    float: left;
    height: 1em;
    width: 1em;
    font-size: 2em;
    padding: 0.125em;
    border-radius: 100%;

    @include mobile {
      display: none;
    }
  }

  .close {
    cursor: pointer;
    color: $action;
    text-align: right;
  }

  .icon-info {
    color: $action;
    &:after {
      content: "\E88F";
      font-family: "Material Icons";
    }
  }

  .icon-success {
    color: $green;
    &:after {
      content: "\E876";
      font-family: "Material Icons";
    }
  }

  .icon-warning {
    color: $yellow;
    &:after {
      content: "\E001";
      font-family: "Material Icons";
    }
  }

  .icon-error {
    color: $red;
    &:after {
      content: "\E001";
      font-family: "Material Icons";
    }
  }

  &-text {
    display: inline-block;
    margin-left: 1em;
    color: $blue;
  }

  &-title {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 0.5em;
    position: relative;
    max-width: 500px;
  }

  &-message {
    font-size: 1.125em;
    max-width: 500px;
  }

  &.info {
    background-color: $blue-light-background;
    border-color: $action;
  }

  &.success {
    background-color: $green-light-background;
    border-color: $green;
  }

  &.warning {
    background-color: $yellow-light-background;
    border-color: $yellow;
  }

  &.error {
    background-color: $red-light-background;
    border-color: $red;
  }
}

.group-inner {
  .alert {
    margin-bottom: 2em;
  }
  .close {
    display: none;
  }
}
