.react-datepicker {
  &-popper {
    // selects are set to 5 in renderer.scss, we need this higher!
    // there is a datepicker brought in with the npm module, so we need to important it.
    z-index: 6 !important;
  }

  &-wrapper {
    display: inherit !important;
  }

  &__input-container {
    display: inherit !important;
  }
}
