.info-box {
  background-color: $gray-lighter;
  color: $gray-dark;

  .field {
    margin-bottom: 0;

    label {
      font-size: 1.125em;
      margin-left: 1em;

      input {
        margin-left: -1.5em;
        margin-right: 0.5em;
      }
    }
  }

  .info-message {
    font-size: 1.125em;
    margin-left: 1em;
  }

  @include mobile {
    padding: 1em;
  }
}
