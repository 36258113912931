.payment-amount-wrapper {
  text-align: left;

  .right-side {
    float: right;
    font-weight: 700;
    color: $black;
  }

  .no-children .title::after {
    content: "";
    height: 0;
  }

  .split-payment-list {
    .field {
      margin-bottom: 0;
    }

    .field-supplement {
      display: flex;

      label {
        flex-grow: 1;
        margin-bottom: 1.6em;
      }
    }

    .split-description {
      display: block;
      margin-bottom: 1em;
    }

    input[type~="text"],
    > .subtotal {
      text-align: right;
    }

    .line-item-total {
      color: $gray-dark;
      text-align: right;
    }

    > .subtotal {
      display: block;
      font-weight: 700;
    }
  }
}
