.payment_amount_input {
  .alert.error {
    max-width: 100%;
  }

  /*
        Terms & Conditions needs to be part of a group in order to participate in form validation
        and also needs the ability to show/hide itself without leaving behind an empty group
        so stick it in the group above it, then override the styles to make them look like separate groups
    */
  .payment-amount-group {
    .group-inner {
      background: none;
      border: 0;
      margin: 0;
      padding: 0;

      > div {
        margin-left: 0;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .accordion-wrapper,
      .info-box {
        background: $white;
        border: 1px $gray-light solid;
        border-radius: 10px;

        @include desktop {
          min-width: 700px;
        }

        @include large-screen {
          min-width: 700px;
        }

        & > div {
          margin-left: 2em;
          margin-right: 2em;

          @include mobile {
            margin-left: 1em;
            margin-right: 1em;
          }
        }

        & > div:first-child {
          margin-top: 1.6em;
        }

        & > div:last-child {
          margin-bottom: 1.6em;
        }
      }
    }

    .info-box {
      padding: 0;

      .field {
        display: flex;

        .error {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
