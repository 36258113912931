.workflow {
  .button-group {
    button {
      background: $white;
      border: 1px solid $gray;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1em;
      margin-right: 0.5em;
      min-width: 80px;
      padding: 16px 14px;
      color: $gray-dark;

      &.selected {
        border-color: $action-dark;
        color: $white;
        cursor: initial;
        font-weight: 500;
        outline: none;
      }
    }
  }
  // When a button group is immediately after a dropdown
  // Add some top margin to make the spacing look right
  .select-wrapper + .button-group,
  .button-group + .text-group,
  .field + .field + .button-group,
  .field + .text-group + .button-group,
  .group-inner > .text-group + .button-group,
  .group-inner > .field + .button-group {
    margin-top: 1.6em;
  }
}
