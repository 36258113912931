.activity .ReactTable {
  width: auto;
  font-size: 0.9em;
  line-height: 1.5em;
  background-color: $white;
  box-shadow: $tile-shadow;

  .rt-th {
    padding: 1.5em 1em 1em !important;
    text-align: right;
  }

  .mobile-column-header {
    float: left;
    color: $gray-dark;
    @include desktop {
      display: none;
    }
  }

  @include desktop {
    min-width: 700px;
  }
}

.payment-items-listview .ReactTable {
  @include desktop {
    min-width: 1000px;
  }
}

.ReactTable {
  padding-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  position: relative;

  @include desktop {
    max-width: 1200px;
  }

  * {
    box-sizing: border-box;
  }

  .rt-table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
  }

  .rt-thead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .rt-tr {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-left: 1em;
      margin-right: 1em;
    }

    &.-header {
      box-shadow: none;
      color: $gray-dark;

      .rt-th {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
        flex: 1 0 0;
        position: relative;
        margin-left: 0.5em;
      }

      @media (max-width: 768px) {
        .rt-th {
          display: none;
          &:first-of-type {
            margin-left: 0;
            display: block;
            -webkit-box-flex: 1;
            -ms-flex: 1 0 0px;
            flex: 1 0 0;
            position: relative;
          }
        }
      }
    }

    .rt-th {
      border-right: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
      flex: 1 0 0;
      padding: 0 0.5rem 1rem 0;

      &.-cursor-pointer {
        cursor: pointer;
      }

      &.-sort-desc {
        color: $action;

        &:before {
          background-color: $white;
          color: $action;
          content: "\E5C5";
          display: inline;
          font-family: "Material Icons";
          font-size: 1.2em;
          opacity: 1;
          position: absolute;
          right: 1em;
          vertical-align: middle;
          z-index: 1;
        }

        &:hover:before {
          color: $action;
          content: "\E5C5";
        }
      }

      &.-sort-asc {
        color: $action;

        &:before {
          background-color: $white;
          color: $action;
          content: "\E5C7";
          display: inline;
          font-family: "Material Icons";
          font-size: 1.2em;
          opacity: 1;
          position: absolute;
          right: 1em;
          vertical-align: middle;
          z-index: 100;
        }

        &:hover:before {
          color: $action;
          content: "\E5C7";
        }
      }
    }

    &.-filters {
      .rt-th {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
        flex: 1 0 0;
        padding-bottom: 1em;
        padding-top: 0;

        input {
          border-color: $gray;
          padding-bottom: 0.25em;
          padding-top: 0.25em;

          &:focus {
            border-color: $action;
          }
        }
      }
    }
  }

  .rt-tbody {
    border-top: 2px solid $action;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .rt-tr {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      color: $black;

      &-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        border-bottom: 1px solid $gray-light;
        margin-right: 1em;
        margin-left: 1em;
      }

      &.info {
        background-color: $blue-light-background;
        &:hover {
          background-color: darken($blue-light-background, 8%);
        }
      }

      &.success {
        background-color: $green-light-background;
        &:hover {
          background-color: darken($green-light-background, 8%);
        }
      }

      &.warning {
        background-color: $yellow-light-background;
        &:hover {
          background-color: darken($yellow-light-background, 8%);
        }
      }

      &.error {
        background-color: $red-light-background;
        &:hover {
          background-color: darken($red-light-background, 8%);
        }
      }

      &:hover {
        background-color: $gray-lighter;
      }
    }

    .rt-td-val {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .rt-td-mobile-label {
      display: none;
    }

    .rt-td {
      border-right: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
      flex: 1 0 0;
      padding: 1em 0.5em;
      vertical-align: middle;
      white-space: normal;

      button,
      a.btn {
        background-color: $action;
        border: 1px solid $action;
        border-radius: 5px;
        color: $white;
        font-size: large;
        font-weight: 400;
        margin-right: 0.5em;
        min-width: inherit;
        padding: 0.5em 1em;
        text-decoration: none;

        &:hover {
          background-color: darken($action, 5%);
          border-color: darken($action, 5%);
        }

        &:focus {
          background-color: darken($action, 10%);
          border-color: darken($action, 10%);
        }
      }

      .material-icons {
        color: $yellow-dark;
        cursor: help;
      }
    }
  }

  input {
    border: 1px solid $gray-light;
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    font-weight: normal;
    outline: none;

    &:focus {
      background-color: $blue-light-background;
    }
  }

  .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
    opacity: 0;
    pointer-events: none;

    > div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      -webkit-transform: translateY(-52%);
      transform: translateY(-52%);
      -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.-active {
      opacity: 1;
      pointer-events: all;

      > div {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
      }
    }
  }

  .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 90%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  .-pagination {
    display: block;
    font-size: 1em;
    margin: 1.5em 0;
    text-align: center;

    @include mobile {
      margin-left: 2em;
      text-align: left;
    }

    & div {
      display: inline-block;
    }

    .-next {
      outline: none;

      button {
        &:before {
          background-color: $white;
          content: "\E5CC";
          display: inline-block;
          font-family: "Material Icons";
          font-size: 1.2em;
          opacity: 1;
          position: absolute;
          right: -1em;
          vertical-align: middle;
          z-index: 100;
        }
      }
    }

    .-previous {
      outline: none;
      -webkit-outline: none;
      -moz-outline: none;

      button {
        &:before {
          background-color: $white;
          content: "\E5CB";
          display: inline-block;
          font-family: "Material Icons";
          font-size: 1.2em;
          left: -1em;
          opacity: 1;
          position: absolute;
          vertical-align: middle;
          z-index: 100;
        }
      }
    }

    button {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      border: none;
      color: $action;
      cursor: pointer;
      font-size: 1em;
      outline: none;
      -webkit-outline: none;
      -moz-outline: none;
      position: relative;

      &:disabled {
        color: $gray;
        cursor: default;
      }
    }

    .-pageInfo {
      color: $gray-dark;
      margin: 0 1em;
    }
  }

  @include mobile {
    .-filters {
      display: none;
    }

    .rt-tbody {
      border-top: inherit;
      // important needed to overwrite inline styles that are added to the "tbody" by component
      min-width: inherit !important;

      .rt-tr {
        display: inline-block;
        padding: 8px 0 12px;

        &-group {
          border-bottom-color: $gray;

          &:first-child {
            border-top: 1px solid $gray;
          }
        }

        .rt-td-val {
          font-size: 1.125rem;
          color: $cool_grey;
        }

        .rt-td {
          // important needed to overwrite inline styles that are added to the "td" by component
          width: inherit !important;
          padding: 1em 0 0.5em;

          &:not(:first-child) {
            padding-left: 36px;
          }

          &:nth-child(2) {
            margin: -40px 0 0;
          }

          &-mobile-label {
            display: block;
            white-space: pre;
            color: $storm_grey;
            font-size: 0.88rem;
            margin-bottom: 4px;
          }

          .multiple-select {
            display: flex;
            justify-content: space-between;

            &-restricted-val:after {
              color: $storm_grey;
              content: attr(data-label);
              padding-left: 1em;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}

#multiple-select-all-checkbox {
  @include mobile {
    margin-right: 1rem;
  }
}
