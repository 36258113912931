.typeahead {
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  &-input {
    height: 100%;
    border: none;

    &::placeholder {
      font-weight: 300;
      font-style: italic;

      @include mobile {
        font-size: large;
      }
    }

    &::-ms-clear {
      display: none;
    }
  }

  &-results-container {
    background-color: $gray-lighter;
    box-shadow: inset 0px 3px 5px -5px $black;
    box-sizing: border-box;
    left: 0;
    padding: 1em;
    position: absolute;
    top: 100%;
    width: 100%;

    .typeahead-results {
      position: relative;

      h3 {
        color: $black;
        font: bold 1.25em/1.25em $sans-serif;
        margin-top: 0;
        text-align: left;
      }

      &-menu {
        background: $gray-lighter;
        border-top: none;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &-close {
        background: none;
        border: none;
        color: $gray-dark;
        cursor: pointer;
        float: right;

        i {
          font-size: 3em;
        }
      }

      li > .search-typeahead-button {
        box-shadow: $tile-shadow;
        box-sizing: border-box;
        margin: 1.25em 0 0.75em;
        width: 100%;

        &:after {
          content: "\E5C8";
          font-family: "Material Icons";
          font-size: 1.1em;
          margin-left: 0.5em;
          vertical-align: bottom;
        }
      }
    }

    @include mobile {
      padding: 1em 0.5em;
    }
  }
}
