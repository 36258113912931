.autocomplete.field {
  position: relative;

  select {
    display: block;
    font-size: 1em;
    padding: 0.3em 0 0.6em;
    width: 100%;
  }
}
