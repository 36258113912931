// colors
$action: #0074d9 !default;
$action-dark: #005095 !default;
$black: #091325 !default;
$blue: #14284b !default;
$blue-dark: #091325 !default;
$blue-light: #1c3c71 !default;
$blue-light-background: #f4faff !default;
$gray: #959ea7 !default;
$gray-dark: #505660 !default;
$gray-light: #cfd4de !default;
$gray-lighter: #f6f6f9 !default;
$gray-help-box: #f8f9f9;
$cool_grey: #292a33;
$storm_grey: #6d717e !default; // CBS-700
$green: #16c98d !default;
$green-dark: #04a670 !default;
$green-light: #2cdea6 !default;
$green-light-background: #dcf7ee !default;
$red: #c3181f !default;
$red-dark: #9c0505 !default;
$red-light: #ed1c24 !default;
$red-light-background: #fcf4f4 !default;
$white: #fff;
$yellow: #f8b700 !default;
$yellow-dark: #ed9620 !default;
$yellow-light: #ffd459 !default;
$yellow-light-background: #fef4d7 !default;

// type
$sans-serif: sans-serif;

// shadows
$box-shadow: rgba(0, 0, 0, 0.5) !default;
$tile-shadow: 0 1px 3px 0 $box-shadow;

$link-color: $action !default;

$notification-bg-color: #1b87d4 !default;
$notification-text-color: $white;

$btn-primary-color: $green;
$btn-secondary-color: $action;
$btn-lighten: 1%;
$btn-darken: 6%;
$btn-primary-color-hover: lighten($btn-primary-color, $btn-lighten);
$btn-primary-color-active: darken($btn-primary-color, $btn-darken);
$btn-secondary-color-hover: lighten($btn-secondary-color, $btn-lighten);
$btn-secondary-color-active: darken($btn-secondary-color, $btn-darken);
$btn-tertiary-color-hover: darken($white, $btn-darken);
$btn-tertiary-color-active: darken($white, 8%);
$btn-shadow-active: 0 1px 2px 0 $box-shadow;
