html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  @include desktop {
    display: flex;
    flex-direction: column;
  }

  @include mobile {
    .noscroll {
      overflow: hidden;
    }
  }
}

main {
  @include desktop {
    display: flex;
    flex: 1 0 auto;
    flex-direction: inherit;
  }
}

#app {
  display: inherit;
  flex: 1 0 auto;
  flex-direction: inherit;
}

.container {
  box-sizing: border-box;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1em;

  @include mobile {
    max-width: 720px;
    width: 100%;
  }

  @include large-screen {
    max-width: 1260px;
  }
}

section {
  &.alt {
    background-color: $gray-lighter;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
  }
}

.btn {
  display: inline-block;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(89, 89, 89, 0.5);
  font-size: 1.2em;
  min-width: 160px;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;

  &.primary {
    color: $white;

    &:hover {
      text-decoration: none;
    }

    &:active {
      box-shadow: $btn-shadow-active;
    }
  }

  &.secondary {
    &:hover {
      text-decoration: none;
    }

    &:active {
      box-shadow: $btn-shadow-active;
    }
  }

  &.external {
    &:after {
      content: "\E89E";
      font-family: "Material Icons";
      float: right;
      font-size: 1em;
      vertical-align: bottom;
    }
  }

  &:disabled {
    background-color: $gray;
    border: 0;
    box-shadow: none;

    &.spinner {
      padding: 8px 24px;
      cursor: auto;

      img {
        height: 2em;
      }
    }
  }

  &.use-action {
    margin-bottom: 1.5rem;
  }
}

.themeWorkflowContainer.auth {
  .btn {
    &.auth {
      color: white;
      background-color: $btn-primary-color;
      width: 360px;
      font-weight: 700;
      box-shadow: 0px 2px 4px 0px rgba(89, 89, 89, 0.5);
    }

    &.auth-link {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: #0074d9;
      padding: 1em 0;
      font-size: 17px;

      &.right {
        text-align: right;
      }
    }
  }
  .field {
    .field {
      margin-bottom: 1em;
    }

    .input-container {
      position: relative;

      .auth.icon.eye {
        position: absolute;
        right: 2em;
        top: 0.8em;
        bottom: 0.5em;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
      }
    }
  }

  .confirm-field {
    margin-top: 1em;
  }

  input[type="checkbox"] {
    margin-right: 1em;
  }
}

.themeWorkflowContainer.profile.workflow {
  .field:not(.button-group) {
    padding: 0;
    border: none;

    &.select-wrapper {
      &::after {
        bottom: 16px;
      }
    }

    label {
      font-size: 1.2rem !important;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;
      color: $gray-dark;
    }

    input,
    select {
      border: 1px solid #caced8;
      padding: 0.75rem;
      font-size: 1.1rem;
      height: 52px;
    }
  }
}

@include mobile {
  .hide-mobile {
    display: none;
  }
}
