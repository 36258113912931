.accordion-wrapper {
  .cc-img-wrapper {
    float: right;
  }

  .title {
    border-left: 3px solid transparent;
    position: relative;

    &:before {
      content: "\E836";
      color: $gray;
      font-family: "Material Icons";
      font-size: 1.1em;
      margin-right: 0.5em;
      vertical-align: bottom;
    }
  }
  .open {
    .title {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::before {
        content: "\E837";
        font-family: "Material Icons";
        font-size: 1.1em;
        margin-right: 0.5em;
        vertical-align: bottom;
      }

      &::after {
        content: "";
        height: 2px;
        width: 100%;
        background-color: $gray-light;
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }
  }

  .accordion {
    border: 1px $gray-light solid;
    border-bottom: none;
    cursor: pointer;
    padding: 1em;
    background-color: $gray-lighter;

    .title {
      display: block;
      word-wrap: break-word;
      color: $gray-dark;
      font-size: 1em;
      font-weight: 400;
      background-color: $white;
      margin: -1em;
      padding: 1.25em;
    }

    .content {
      margin-top: 2em;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .title {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom: 1px $gray-light solid;

      .title {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.open .title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .error {
    color: $red;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0.4em;
    font-size: 1.7em;
    font-weight: 700;
  }
}
