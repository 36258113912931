@mixin desktop() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin portraitTabletDown() {
  @media only screen and (max-width: 1048px) {
    @content;
  }
}

@mixin mobile() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin large-screen() {
  @media only screen and (min-width: 1260px) {
    @content;
  }
}
