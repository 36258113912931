.payment-details {
  border-top: none;
  border-bottom: 1px solid $gray-light;

  h2 {
    margin-bottom: 0.4em;
  }

  .line-items {
    background: $white;
    border: 1px $gray-light solid;
    border-radius: 10px;
    padding: 1em;

    .line-item {
      color: $gray-dark;
      display: flex;
      padding-bottom: 1.2em;

      &:last-child {
        margin-top: 2em;
      }

      &-description {
        flex-grow: 4;

        .line-item-subdescription {
          color: $gray;
        }

        &.subtotal,
        &.service-fee,
        &.grand-total {
          text-align: right;
        }

        &.grand-total,
        &.grand-total + .line-item-value {
          font-weight: bold;
        }
      }

      &-value {
        flex-basis: 20%;
        padding-left: 10px;
        text-align: right;
      }
    }
  }

  .title {
    text-align: left;
    font-weight: 700;
    color: $black;
  }

  .info-box {
    background-color: $gray-light;
    border-radius: 0;
    color: $gray-dark;
    margin-top: 20px;
    padding: 1em;
    text-align: left;
  }
}

.confirmation {
  .payment-details {
    border-bottom: none;

    .line-items {
      border: none;
      padding: 0;
    }
  }
}
