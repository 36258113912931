.link {
  &-group {
    margin: 1em 0;
  }

  &-item {
    margin-bottom: 0.5em;

    a {
      text-decoration: underline;
      color: $action;

      &:hover {
        color: $blue-light;
      }

      &.btn {
        color: $white !important;
      }
    }
  }

  &-description {
    color: $gray;
  }
}
