.notification {
  background: $notification-bg-color;
  color: $notification-text-color;
  padding: 0.1em;

  &-text {
    font-size: 0.9em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    text-align: center;
  }

  a {
    color: $notification-text-color;
  }

  .learn-more {
    color: $notification-text-color;
    font-size: 0.8em;
    font-weight: 300;
    margin-left: 0.5em;

    &::after {
      content: "\E5C8";
      font-family: "Material Icons";
      font-size: 1.1em;
      margin-left: 0.2em;
      vertical-align: bottom;
    }
  }
}
