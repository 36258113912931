.payment_form {
  .workflow-wrapper {
    // There is padding on the outside of the two columns in payment_form.
    // This gradient makes it seem like the columns go full width even though they stay within .container
    background: linear-gradient(
      to right,
      $white 0%,
      $white 43%,
      $gray-lighter 44%,
      $gray-lighter 100%
    );
    padding: 0;

    @include mobile {
      background: none;
    }
  }

  .container {
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }

  .workflow {
    display: inherit;
  }

  .workflow-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .group {
      box-shadow: 2.5px 0 2px rgba(0, 0, 0, 0.2);
      background: $white;
      box-sizing: border-box;
      flex: 0 0 50%;
      margin-top: 0;
      order: 0;
      padding: 2em 1em 2em 0;

      .group-inner {
        min-width: 0;
      }
      > .group-inner {
        border: none;

        .info-message {
          color: $gray-dark;
        }

        .hidden-toggle {
          margin-bottom: 1em;
        }

        > .btn-wrapper {
          margin-top: 3em;
          width: auto;
          padding-right: 0;

          .btn {
            width: 100%;
            box-sizing: border-box;
            margin-left: 0;
            margin-bottom: 1em;
          }
        }
      }

      @include large-screen {
        .btn {
          width: auto !important;
          margin-top: 0;
          vertical-align: top;
        }

        & .tertiary {
          float: left;
        }

        & .secondary {
          float: left;
        }
      }

      @include mobile {
        box-shadow: none;
        flex: 0 0 100%;
        order: 1;
        padding-right: 0;
      }
    }
  }

  .payment-details {
    box-sizing: border-box;
    flex: 0 0 45%;
    order: 1;
    padding: 2em 0 2em 1em;

    h2 {
      font-size: 1.7em;
      text-align: left;

      @include mobile {
        display: none;
      }
    }

    @include mobile {
      background-color: $gray-lighter;
      flex: 0 0 100%;
      order: 0;
      padding: 1.4em 1em 1em;
    }
  }

  .line-item-description {
    text-align: left;
  }
}
