.credit-card-number {
  .cc-img-wrapper {
    float: right;
  }

  .cc-img {
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 32px;

    &.amex {
      background-image: url("../static/AmEx.svg");
    }
    &.discover {
      background-image: url("../static/Discover.svg");
    }
    &.mastercard {
      background-image: url("../static/MasterCard.svg");
    }
    &.visa {
      background-image: url("../static/Visa.svg");
    }

    &.dimmed {
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
  }
}
