.modal {
  .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10000;
  }

  &-container {
    background: $white;
    border-radius: 0.5em;
    box-sizing: border-box;
    box-shadow: $tile-shadow;
    max-width: 650px;
    position: fixed;
    text-align: left;
    top: 10%;
    width: 60%;
    z-index: 10001;

    .header {
      background: $gray-light;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
      margin-bottom: 0.5em;
      min-height: 1em;
      padding: 1em 1.5em 1em 1em;

      .material-icons {
        color: $gray-dark;
        cursor: pointer;
        float: right;
        vertical-align: top;
      }

      .title {
        display: inline-block;
        font-size: 1.25em;
        font-weight: 700;
        width: 90%;
      }
    }

    .body {
      max-width: 100%;
      padding: 1em 1.5em 1em 1em;
      max-height: 400px;
      overflow-y: scroll;

      .ReactTable {
        width: inherit;
      }
    }
  }
}
