@import "components/accordion-wrapper";
@import "components/autocomplete";
@import "components/button-group";
@import "components/credit-card-number";
@import "components/listview";
@import "components/link";
@import "components/object-list";
@import "components/parcel-lookup";
@import "components/payment-amount-wrapper";
@import "components/modal";

// styles for the workflow renderer
.workflow-main-wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 100%;
}

.workflow-wrapper {
  background: $gray-lighter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 100px;
  padding-top: 60px;

  .container {
    text-align: center;
  }
}

.workflow {
  color: $black;
  margin: 0 auto;
  display: inline-block;

  @include mobile {
    padding-bottom: 80px;
    width: 100%;
  }

  .workflow-title {
    font-size: 2em;
    color: $black;
  }

  section {
    border: solid 1px $black;
    padding: 1em;

    .section-title {
      font-size: 1.6em;
      font-weight: 500;
      text-align: center;
    }
  }

  .wiki-text {
    text-align: left;
    line-height: 1.25em;
    max-width: 640px;
    margin-bottom: 1em;
    margin-top: 1em;

    p {
      overflow-wrap: break-word;
      line-height: 1.5;
      margin-top: 1em;
      margin-bottom: 2em;

      &.wiki-amount {
        margin-bottom: 0;
        font-weight: bold;
        display: block;
      }

      @include desktop {
        &.wiki-amount {
          display: none;
        }
      }
    }

    a {
      color: $action;
      word-break: break-all;

      &:hover {
        color: $blue-light;
      }
    }

    h2 {
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      font-size: 1.7em;
      font-weight: 700;
      line-height: 1.25em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      max-width: 700px;
    }

    h4 {
      font-size: 1.25em;
      font-weight: 400;
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    h5 {
      margin-top: 0em;
      margin-bottom: 0em;
      font-size: 1.125em;
      font-weight: 700;
      max-width: 700px;
      line-height: 1.3em;
    }

    &-info-group {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    br:last-of-type {
      display: none;
    }
  }

  .group {
    margin-top: 2.5em;
    text-align: left;

    .group-inner {
      position: relative;
      background: $white;
      border: 1px $gray-light solid;
      border-radius: 10px;

      @include desktop {
        min-width: 700px;
      }

      @include large-screen {
        min-width: 700px;
      }

      .text-group {
        hr {
          margin-top: 2em;
          margin-bottom: 1.6em;
        }
      }

      .control-group-fieldset {
        border: none;
        padding: 0;
        margin: 0;
      }

      .group-content {
        margin-left: 2em;
        margin-right: 2em;

        @include mobile {
          margin-left: 1em;
          margin-right: 1em;
        }
      }

      .control-group-fieldset:first-child,
      & > div:first-child {
        margin-top: 1.6em;

        &.table-wrapper {
          margin: 0.5em 0 1em 0;
        }
      }

      .control-group-fieldset:last-child,
      & > div:last-child {
        margin-bottom: 1.6em;

        &.table-wrapper {
          margin: 0.5em 0 1em 0;
        }
      }

      & > div.table-wrapper {
        margin: 0.5em 0 1em 0;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    .group-title {
      margin-top: 0;
      margin-bottom: 0.4em;
      font-size: 1.7em;
      font-weight: 700;
    }
  }

  .field {
    position: relative;
    word-wrap: break-word;

    &.half-width {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      select,
      textarea {
        width: 49%;
      }
    }

    label {
      display: block;
      word-wrap: break-word;
      color: $gray-dark;
      font-size: 14px;
      margin-bottom: 0.25em;
    }

    h3 {
      word-wrap: break-word;
    }

    &.button-group {
      margin-bottom: 1.6em;
      label {
        margin-bottom: 0.3em;
      }
    }

    textarea {
      min-height: 70px;
      width: 100%;
      resize: vertical;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select,
    textarea {
      background: $white;
      box-sizing: border-box;
      display: block;
      border: 1px $gray-dark solid;
      font-size: 1em;
      padding: 0.9em 0.6em;
      width: 100%;
      border-radius: 0;
      box-shadow: none;

      &:focus {
        border-color: $action;
        background: $blue-light-background;
        outline: none;
      }

      &:disabled {
        border-color: $gray-light;
        background-color: $gray-lighter;
      }

      @include desktop {
        &.half-width {
          width: 50%;
          display: inline-block;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      -webkit-appearance: none;
    }

    &.select-wrapper {
      position: relative;

      .half-width {
        width: 49%;
        display: inline-block;
        height: 49px;

        &:nth-of-type(2) {
          float: right;
        }

        &:first-of-type::after {
          content: "";
          border: 5px transparent solid;
          border-top-color: $gray-dark;
          bottom: 26px;
          float: right;
          position: relative;
          right: 10px;
          z-index: 6;
          pointer-events: none;
        }
      }

      select {
        position: relative;
        z-index: 5;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-sizing: border-box;
        display: block;
        font-size: 1em;
        padding: 0.9em 1.8em 0.9em 0.6em;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &::-ms-expand {
          display: none;
        }

        option {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &::after {
        content: "";
        position: absolute;
        border: 5px transparent solid;
        border-top-color: $gray-dark;
        right: 1.8em;
        bottom: 36px;
        z-index: 6;
        pointer-events: none;
      }
    }

    &.radio-group,
    &.checkbox-group {
      label {
        padding-bottom: 1em;
        line-height: 1.25em;
        margin-left: 1.5em;

        &:first-child {
          padding-top: 1em;
        }

        &:first-child.field-label {
          margin-left: 0;
          padding-top: 0;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        display: inline-block;
        vertical-align: top;
        margin: 0 0.5em 0.25em -0.5em;
        transform: translateY(2px);
      }
    }

    .error {
      color: $red;
      font-size: 0.8em;
    }

    &.error {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      select,
      textarea {
        border-color: $red-light;
      }
    }

    ul {
      margin-top: 0;
      padding-left: 1.5em;
    }

    li {
      margin-bottom: 0.5em;
    }
  }

  .payment-items-listview {
    @include portraitTabletDown {
      margin: 0;
      padding: 0;
    }

    .error {
      color: $red;
      font-size: 1em;
    }
  }

  /*
  Styles that position text element completely off-screen
  Text using this class will not be visible to sighted users
  Use this if you need to provide text within a workflow
  for users of assistive technologies
  */
  span.sr-only-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .btn-wrapper {
    margin-top: 75px;
    text-align: right;
    border-top: 2px $gray-light solid;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include mobile {
      > div {
        flex: 1;
        &:first-child {
          margin: 0 4px 0 8px;
        }
        &:last-child {
          margin: 0 8px 0 4px;
        }
      }
    }

    .btn {
      margin-left: 10px;
      min-width: 208px;
      box-sizing: border-box;

      &.secondary {
        border: none;
        font-size: 1.2em;

        &:focus {
          outline: none;
        }
      }

      &.tertiary {
        float: left;

        @include mobile {
          float: none;
          margin-top: 1em;
        }
      }

      @include mobile {
        min-width: auto;
        box-sizing: border-box;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .group .btn-wrapper {
    margin-top: 0;
    border-top: none;
    box-sizing: border-box;
  }

  &.workflow-step-2 {
    .group {
      .group-inner {
        padding-bottom: 20px;
      }
    }

    .field {
      margin-bottom: 2em;

      label {
        margin-bottom: 0.5em;
      }
    }

    .group .btn-wrapper {
      bottom: -98px;
      border-top: none;

      .btn {
        background: $btn-primary-color;

        &:focus {
          background: $btn-primary-color-active;
        }
      }
    }
  }

  @include mobile {
    .field {
      margin-bottom: 1.3em;
    }

    .btn-wrapper {
      margin-top: 2em;
      padding: 2em 0 1em;

      .btn {
        display: block;
      }
    }

    .group .btn-wrapper {
      padding-right: 0;
    }
  }

  &.workflow-step-2 {
    .group {
      .group-inner {
        padding-bottom: 10px;
      }
    }
  }
}

.wikiRow {
  border-bottom: 1px solid $gray-light;
  width: 100%;
  padding: 0.5em 2em 1.5em 1em;
  box-sizing: border-box;

  .otherDeductionsButtons {
    float: right;

    @include mobile {
      display: block;
      float: none;
      margin-top: 1.5em;
    }

    a {
      padding: 0.5em 1em;

      &.moreInfoButton {
        border: 1px solid $gray-dark;
        border-radius: 5px;
        color: $gray-dark;
        font-weight: 400;

        &:hover {
          background-color: $gray-lighter;
        }

        &:focus {
          background-color: $gray-light;
        }
      }

      &.applyButton {
        background-color: $action;
        border: 1px solid $action;
        border-radius: 5px;
        color: $white;
        font-weight: 400;
        margin-right: 0.5em;

        &:hover {
          background-color: darken($action, 5%);
          border-color: darken($action, 5%);
        }

        &:focus {
          background-color: darken($action, 10%);
          border-color: darken($action, 10%);
        }
      }
    }
  }
}

.confirmation {
  box-sizing: border-box;
  min-height: calc(100vh - 97px - 168px);
  background: $white;
  flex: 1 0 auto;

  .workflow {
    .group {
      margin-top: 1em;
    }

    .workflow-inner {
      // this is not fragile at all.
      div.group:nth-of-type(2) {
        margin-top: 14em;
      }
    }

    .btn-wrapper {
      margin-top: 2em;
    }

    .wiki-text {
      max-width: 700px;
    }

    .confirmation-banner {
      background-color: $white;
      width: 100vw;
      position: absolute;
      left: 0;
      top: 160px;
      padding-bottom: 2em;
      padding-top: 2em;
      border-bottom: 1px solid $gray;
      text-align: center;

      p {
        width: 40vw;
        margin: auto;

        @include mobile {
          width: 85vw;
        }
      }

      .confirmation-success-image {
        background-image: url("/static/icon-confirmation-thank-you.png");
        background-repeat: no-repeat;
        background-position: center;
        height: 82px;
      }
    }
  }
}

.checkbox-large {
  -ms-transform: scale(1.25); /* IE */
  -moz-transform: scale(1.25); /* FF */
  -webkit-transform: scale(1.25); /* Safari and Chrome */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
  @include mobile {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
  }
}
